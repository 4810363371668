import React, { memo, useMemo } from 'react';
import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { SettingsAdapterProvider, StylesParamsProvider } from '../../../hooks';
import { DefaultSettingsAdapter } from '../DefaultSettingsAdapter';
import settingsParams from '../settingsParams';
import stylesParams from '../stylesParams';

export const WidgetContext: React.FC = memo(({ children }) => {
  const settings = useSettings();
  const settingsAdapter = useMemo(() => new DefaultSettingsAdapter(settings, settingsParams), [settings]);
  const { isRTL } = useEnvironment();
  return (
    <StylesParamsProvider stylesParams={stylesParams}>
      <SettingsAdapterProvider adapter={settingsAdapter}>
        <div dir={isRTL ? 'rtl' : 'ltr'}>{children}</div>
      </SettingsAdapterProvider>
    </StylesParamsProvider>
  );
});
